import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "index": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "lp": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "terms_of_service": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "privacy_policy": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "refund_policy": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "7step_slider_blog": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "lastlongerinbed_slider_blog": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "mealplan_slider_blog": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "testmeas_slider_blog": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "tt": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "ft": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "q1": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "q1/coach-supp": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "q1/pers-care": {
        "background": "#fff",
        "overflow-x": "hidden"
    },
    "fonts-tester": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "template-lp": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "template-tt": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "template-tt-old": {
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
